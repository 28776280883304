import React from "react"
import EmbedSlidesPresentation from "../../components/EmbedSlidesPresentation/EmbedSlidesPresentation"
import SlidesPresentation from "../../templates/slides-presentation"

const StaffingIndustryNl = (): React.ReactElement => {
  return (
    <SlidesPresentation>
      <EmbedSlidesPresentation iframeSrc="https://slides.com/bramtierie/flipbase-asynchrone-video-interactie-staffing-nl/embed" />
    </SlidesPresentation>
  )
}

export default StaffingIndustryNl
